export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    bgcolor: '#F5F5F5',
    paddingX: { xs: '10px', md: '100px', lg: '100px' },
    paddingBottom: { xs: '16px', sm: '38px' },
  },
  iconContainer: {
    marginY: { xs: '24px', sm: '38px' },
  },
  contentArea: {
    width: '100%',
    minHeight: '80vh',
    bgcolor: 'white',
    borderRadius: '12px',
    paddingTop: { xs: '14px', md: '30px', lg: '40px' },
    boxShadow: '0px 4px 17.4px 0px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '42px',
  },
  headingContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', lg: 'row-reverse' },
    alignItems: 'center',
    width: '100%',
    gap: '12px',
    marginTop: { xs: '0px', lg: '0px' },
    paddingX: { xs: '14px', md: '21px', lg: '27px' },
  },
  heading: {
    flexGrow: 1,
    textAlign: 'center',
  },
  closeIconContainer: {
    border: '1px solid gray',
    borderRadius: '4px',
    width: '34px',
    height: '34px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    px: { xs: '10px', sm: '30px', md: '50px', lg: '90px' },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row-reverse' },
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '40px',
    paddingX: '10px',
  },
};
