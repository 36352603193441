import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledSelectInput = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const customStyles = (icon?: React.ReactNode, theme?: any) => ({
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: theme.palette.text.secondary,
  }),
  option: (baseStyles: any, { isSelected }: any) => ({
    ...baseStyles,
    cursor: 'pointer',
    backgroundColor: isSelected ? theme.palette.secondary.main : 'inherit',
  }),
  control: (baseStyles: any) => ({
    ...baseStyles,
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    marginBottom: '12px',
    width: '440px',
    height: '55px',
    borderRadius: '4px',
    padding: '0 12px',
    backgroundColor: '#FFFFFF',
    border: `2px solid ${theme.palette.grey[50]}`,

    '&:hover': {
      border: `2px solid ${theme.palette.secondary.main}`,
    },

    '&:focus-within': {
      border: `2px solid ${theme.palette.secondary.main}`,
      boxShadow: `0px 0px 0px 5px rgba(39, 150, 96, 0.1)`,
    },
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    paddingLeft: icon ? '36px' : '12px',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    width: '440px',
  }),
});
