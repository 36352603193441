import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { StyledContainer } from './WrongAddress.styles';
import { WrongAddressIcon } from '@/assets/icons';
import { FormActions } from '@/components';

const WrongAddress = () => {
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <WrongAddressIcon />
      <Typography
        variant="body1"
        color="initial"
        textAlign="center"
        fontWeight="600"
      >
        Sorry we don’t offer services to your location right now!
      </Typography>
      <FormActions
        onSubmit={() => {
          navigate('/services/address');
        }}
      />
    </StyledContainer>
  );
};

export default WrongAddress;
