import { FC, useContext, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { styles } from './Layout.styles';
import { Box } from '@mui/material';
import { CustomButton } from '@/components';
import { UserFormContext } from '@/context';
import { useFormNavigation } from '@/hooks';

interface FormActionsProps {
  onSubmit?: () => void;
}

const FormActions: FC<FormActionsProps> = ({ onSubmit }) => {
  const location = useLocation();
  const [footerPortalDOM, setFooterPortalDOM] = useState<HTMLElement | null>(
    null,
  );
  const { step, isLoading } = useContext(UserFormContext);
  const { handleBack } = useFormNavigation();

  const isWrongAddressPage = location.pathname === '/services/wrong-address';

  useLayoutEffect(() => {
    const targetElement = document.getElementById('footer-actions-portal');
    setFooterPortalDOM(targetElement);
  }, []);

  if (!footerPortalDOM) return null;

  return createPortal(
    <Box sx={styles.buttonContainer}>
      {!isWrongAddressPage && (
        <CustomButton
          onClick={onSubmit}
          loading={isLoading}
          sx={{
            ...(step === 1 && { width: { xs: '100%', sm: '275px' } }),
          }}
        >
          {step === 8 ? 'Submit' : 'Continue'}
        </CustomButton>
      )}
      {step !== 1 && (
        <CustomButton variant="outlined" onClick={handleBack}>
          Go back
        </CustomButton>
      )}
    </Box>,
    footerPortalDOM,
  );
};

export default FormActions;
