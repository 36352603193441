import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: '24px',
  border: `2px solid ${theme.palette.grey[50]}`,
  borderRadius: '8px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  color: theme.palette.grey[200],
}));

export const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: '14px',
});

export const StyledInfoBox = styled(Box)({
  marginTop: '8px',
  backgroundColor: '#2796601A',
  borderRadius: '4px',
  display: 'inline-block',
});

export const StyledInfoText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 600,
  display: 'flex',
  columnGap: '5px',
  alignItems: 'center',
  padding: '11px',
  color: theme.palette.secondary.main,
}));

export const StyledConfirmationMessage = styled(Typography)({
  textAlign: 'center',
  marginTop: '24px',
});
