import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { FormActions, CheckBoxInputHook } from '@/components';
import { UserFormContext } from '@/context';
import { TServiceDetail, ServiceDetailSchema } from '@/utils';
import { useFetchCategoriesByTenantId } from '@/services';
import { FurnaceIcon, OtherIcon } from '@/assets/icons';

const ServiceDetail = () => {
  const { form, saveFormData, tenantId } = useContext(UserFormContext);
  const navigate = useNavigate();

  const formMethods = useForm<TServiceDetail>({
    resolver: zodResolver(ServiceDetailSchema),
    defaultValues: {
      serviceLooking: form?.serviceLooking,
    },
  });

  const { data: categories } = useFetchCategoriesByTenantId(
    tenantId ?? '',
    navigate,
  );

  const filteredSubCategories = [
    ...(categories
      ?.find((c: any) => c.id === form?.serviceNeed)
      ?.subCategories?.map((subCategory: any) => {
        return {
          label: subCategory.name,
          value: subCategory.id,
          image: <FurnaceIcon />,
        };
      }) ?? []),
    {
      label: 'Other',
      value: 'Other',
      image: <OtherIcon />,
    },
  ];

  const handleFormSubmit = async (data: TServiceDetail) => {
    saveFormData(data);
    navigate('/services/address');
  };

  return (
    <FormProvider {...formMethods}>
      <CheckBoxInputHook
        name="serviceLooking"
        options={filteredSubCategories ?? []}
        paddingX={{ xs: '14px' }}
        paddingY={{ xs: '20px' }}
        width={{ xs: '150px', md: '191px', xl: '191px' }}
        gap="32px"
        justifyContent={{ xs: 'center', lg: 'center' }}
        height={{ xs: '150px', md: '182px' }}
      />
      <FormActions onSubmit={formMethods.handleSubmit(handleFormSubmit)} />
    </FormProvider>
  );
};
export default ServiceDetail;
