const InfoIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 1.3335C4.31967 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31967 14.6668 7.99967 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99967 1.3335ZM8.66634 11.3335H7.33301V7.3335H8.66634V11.3335ZM8.66634 6.00016H7.33301V4.66683H8.66634V6.00016Z"
        fill="#279660"
      />
    </svg>
  );
};

export default InfoIcon;
