import { Outlet } from 'react-router-dom';
import getPublicRoutes from './PublicRoutes';
import { TemporaryPage } from '@/pages';

const getRoutes = () => [
  {
    index: true,
    path: '/:tenantId',
    element: <TemporaryPage />,
  },
  {
    path: '/services',
    element: <Outlet />,
    children: [getPublicRoutes()],
  },
];

export default getRoutes;
