import { Box } from '@mui/material';

const PlenumproIcon = () => {
  return (
    <Box
      component="svg"
      sx={{
        width: { xs: '60px', sm: '80px' },
        height: { xs: '60px', sm: '80px' },
      }}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6657_4624)">
        <path
          d="M18.7299 18.7299V49.3662H30.6332V30.6332H49.3662V18.7299H18.7299ZM49.3662 30.6332V49.3662H30.6332V61.2695H61.2695V30.6332H49.3662ZM18.7299 61.2695V79.9994H30.6332V61.2695H18.7299ZM0 49.3662V61.2695H18.7299V49.3662H0ZM61.2695 18.7299V0H49.3662V18.7299H61.2695ZM80 30.6332V18.7299H61.2701V30.6332H80Z"
          fill="#262626"
        />
      </g>
      <defs>
        <clipPath id="clip0_6657_4624">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
};

export default PlenumproIcon;
