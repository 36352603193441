import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { FormActions, CheckBoxInputHook } from '@/components';
import { UserFormContext } from '@/context';
import { ServiceTypeSchema, TServiceType } from '@/utils';
import { useFetchCategoriesByTenantId } from '@/services';
import { FurnaceIcon, OtherIcon } from '@/assets/icons';

const Service = () => {
  const { saveFormData, form, tenantId } = useContext(UserFormContext);
  const navigate = useNavigate();

  const { data: categories } = useFetchCategoriesByTenantId(
    tenantId ?? '',
    navigate,
  );

  useEffect(() => {
    if (!tenantId) {
      navigate('/');
      return;
    }
  }, [tenantId]);

  const formMethods = useForm<TServiceType>({
    resolver: zodResolver(ServiceTypeSchema),
    defaultValues: {
      serviceNeed: form?.serviceNeed,
    },
  });

  const handleFormSubmit = async (data: TServiceType) => {
    const selectedService = newServiceData.find(
      service => service.value === data.serviceNeed,
    );
    saveFormData({
      serviceNeed: data.serviceNeed,
      serviceName: selectedService?.label || '',
    });
    navigate(
      data.serviceNeed === 'Other' ? '/services/address' : '/services/detail',
    );
  };

  const newServiceData = [
    ...(categories?.map((category: any) => {
      return {
        label: category.name,
        value: category.id,
        image: <FurnaceIcon />,
      };
    }) ?? []),
    {
      label: 'Other',
      value: 'Other',
      image: <OtherIcon />,
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <CheckBoxInputHook
        name="serviceNeed"
        options={newServiceData ?? []}
        paddingX={{ xs: '14px' }}
        paddingY={{ xs: '20px' }}
        width={{ xs: '150px', md: '191px', xl: '191px' }}
        gap="32px"
        justifyContent={{ xs: 'center', lg: 'center' }}
        height={{ xs: '150px', md: '182px' }}
      />
      <FormActions onSubmit={formMethods.handleSubmit(handleFormSubmit)} />
    </FormProvider>
  );
};

export default Service;
