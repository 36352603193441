import { FormLayout } from '@/components';
import {
  ServiceDetailPage,
  ServicePage,
  SchedulePage,
  AddressPage,
  HelpPage,
  IssueDetailPage,
  EmergencyPage,
  ContactPage,
  ConfirmationPage,
  WrongAddressPage,
} from '@/pages';

const getPublicRoutes = () => ({
  path: '',
  element: <FormLayout />,
  children: [
    { index: true, element: <ServicePage /> },
    {
      path: 'detail',
      element: <ServiceDetailPage />,
    },
    {
      path: 'address',
      element: <AddressPage />,
    },
    {
      path: 'wrong-address',
      element: <WrongAddressPage />,
    },
    {
      path: 'help',
      element: <HelpPage />,
    },
    {
      path: 'issue-detail',
      element: <IssueDetailPage />,
    },
    {
      path: 'emergency',
      element: <EmergencyPage />,
    },
    {
      path: 'contact',
      element: <ContactPage />,
    },
    {
      path: 'schedule',
      element: <SchedulePage />,
    },
    {
      path: 'confirmation',
      element: <ConfirmationPage />,
    },
  ],
});
export default getPublicRoutes;
