import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  StyledContainer,
  StyledDisabledButton,
  StyledGrid,
  StyledOptions,
  StyledTimeOptions,
} from './Schedule.styles';
import { LeftArrowIcon, RightArrowIcon } from '@/assets/icons';
import { FormActions, CheckBoxInputHook } from '@/components';
import { UserFormContext } from '@/context';
import { IOption, ServiceScheduleSchema, TServiceSchedule } from '@/utils';
import { useFetchSlotsAvailability } from '@/services';
const ServiceSchedule = () => {
  const [currentWeek, setCurrentWeek] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string>('');
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [availableSlots, setAvailableSlots] = useState<IOption[]>([]);
  const [firstDateOfCurrentWeek, setfirstDateOfCurrentWeek] = useState(
    new Date().toISOString().split('T')[0],
  );
  const { form, saveFormData, tenantId } = useContext(UserFormContext);

  const navigate = useNavigate();
  const isSmall = useMediaQuery('(max-width: 400px)');

  const getCurrentMonthYear = useCallback(
    (startDate: Date, endDate: Date) => {
      const startMonth = startDate.toLocaleString('default', {
        month: isSmall ? 'short' : 'long',
      });
      const endMonth = endDate.toLocaleString('default', {
        month: isSmall ? 'short' : 'long',
      });
      setfirstDateOfCurrentWeek(startDate.toISOString().split('T')[0]);
      const endYear = endDate.getFullYear();
      if (startMonth !== endMonth) {
        return `${startMonth} - ${endMonth} ${endYear}`;
      } else {
        return `${startMonth} ${endYear}`;
      }
    },
    [isSmall],
  );

  const generateAvailableDates = (startDate: Date): Date[] => {
    const dates: Date[] = [];
    let date = new Date(startDate);
    date.setDate(
      date.getDate() - (date.getDay() === 0 ? 6 : date.getDay() - 1),
    );
    for (let i = 0; i < 7; i++) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };
  const handleNextWeek = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 7);
    setCurrentDate(nextDate);
  };

  const handlePreviousWeek = () => {
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 7);
    setCurrentDate(previousDate);
  };
  useEffect(() => {
    const newDates = generateAvailableDates(currentDate);
    setCurrentWeek(newDates);
    setCurrentMonth(getCurrentMonthYear(newDates[0], newDates[6]));
  }, [currentDate, getCurrentMonthYear]);

  const formMethods = useForm<TServiceSchedule>({
    resolver: zodResolver(ServiceScheduleSchema),
    defaultValues: {
      dateoptions: form?.date || '',
      timeoptions: form?.time || '',
    },
  });

  useEffect(() => {
    if (form?.date) {
      const selectedDate = new Date(form.date);
      setCurrentDate(selectedDate);
      setCurrentWeek(generateAvailableDates(selectedDate));
      formMethods.setValue('dateoptions', form.date);
    }
    if (form?.time) {
      formMethods.setValue('timeoptions', form.time);
    }
  }, [form?.date, form?.time, formMethods]);

  const { data } = useFetchSlotsAvailability({
    tenantId,
    serviceId: form?.serviceId,
    date: firstDateOfCurrentWeek,
  });

  const selectedDate = formMethods.watch('dateoptions');
  const selectedDateISO = selectedDate
    ? new Date(selectedDate).toISOString().split('T')[0]
    : '';

  useEffect(() => {
    if (data && selectedDateISO) {
      const filteredSlots = data?.data.find((slot: any) =>
        slot.date.startsWith(selectedDateISO),
      )?.slots;

      const slotOptions: IOption[] = (filteredSlots || []).map(
        (slot: string) => ({
          label: slot,
          value: slot,
        }),
      );

      if (JSON.stringify(slotOptions) !== JSON.stringify(availableSlots)) {
        setAvailableSlots(slotOptions);
      }
    }
  }, [selectedDateISO, data]);

  const options = currentWeek.map(date => {
    const isPast = date.getTime() < new Date().setHours(0, 0, 0, 0);
    const dayData = data?.data.find((day: any) => {
      const apiDate = new Date(day.date);
      return (
        apiDate.toISOString().split('T')[0] === date.toISOString().split('T')[0]
      );
    });
    return {
      label: date.getDate().toString(),
      value: date.toISOString(),
      day: date.toLocaleString('default', { weekday: 'short' }),
      slot: dayData ? dayData.slotsCount : '0',
      disabled: isPast || !dayData,
    };
  });

  const handleFormSubmit = async (data: TServiceSchedule) => {
    const selectedDate = new Date(data.dateoptions);
    const selectedTime = data.timeoptions;
    if (selectedDate && selectedTime) {
      saveFormData({ date: selectedDate.toISOString(), time: selectedTime });
      navigate('/services/confirmation');
    }
  };

  useEffect(() => {
    const newDates = generateAvailableDates(currentDate);
    setCurrentWeek(newDates);
    setCurrentMonth(getCurrentMonthYear(newDates[0], newDates[6]));

    // Automatically go to the next week if today is Sunday and disabled
    const today = new Date();
    const lastDayOfWeek = newDates[newDates.length - 1];

    if (
      today.getDay() === 0 && // Today is Sunday
      lastDayOfWeek.toISOString().split('T')[0] ===
        today.toISOString().split('T')[0] && // Last day of week is today
      options.find(
        option =>
          option.value === lastDayOfWeek.toISOString() && option.disabled,
      ) // Today (Sunday) is disabled
    ) {
      handleNextWeek();
    }
  }, [currentDate, getCurrentMonthYear, options]);

  return (
    <StyledContainer>
      <StyledGrid container>
        <Grid item>
          <StyledDisabledButton
            onClick={handlePreviousWeek}
            disabled={currentWeek[0] <= new Date()}
          >
            <LeftArrowIcon />
          </StyledDisabledButton>
        </Grid>
        <Grid item>
          <Typography variant="h6">{currentMonth}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handleNextWeek}>
            <RightArrowIcon />
          </IconButton>
        </Grid>
      </StyledGrid>
      <FormProvider {...formMethods}>
        <StyledOptions>
          <Box>
            <CheckBoxInputHook
              name="dateoptions"
              options={options}
              width={{ xs: '61px', md: '75px', lg: '83px' }}
              height={'106px'}
              gap={{ xs: '7px', lg: '11px' }}
              paddingX={{ xs: '0px' }}
            />
          </Box>
          <StyledTimeOptions>
            <CheckBoxInputHook
              name="timeoptions"
              options={availableSlots || []}
              width={{ xs: '125px', sm: '155px', md: '143px', lg: '155px' }}
              gap={'10px'}
              paddingX={{ xs: '0px' }}
              paddingY={{ xs: '12px' }}
              justifyContent={'start'}
              fontSize="14px"
            />
          </StyledTimeOptions>
        </StyledOptions>
        <FormActions onSubmit={formMethods.handleSubmit(handleFormSubmit)} />
      </FormProvider>
    </StyledContainer>
  );
};
export default ServiceSchedule;
