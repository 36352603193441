import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserFormContext } from '@/context';
import { getPreviousStep, ROUTE_CONFIG } from '@/utils';

const useFormNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { setStep, setHeading, step, form, resetForm, tenantId } =
    useContext(UserFormContext);

  useEffect(() => {
    const routeData = ROUTE_CONFIG.find(value => value.path === currentPath);
    if (routeData) {
      setStep(routeData.step);
      setHeading(routeData.getHeading(form?.personalInfo?.firstName));
    }
  }, [currentPath, setStep, setHeading]);

  const handleClose = () => {
    resetForm();
    navigate(`/${tenantId}`);
  };

  const handleBack = () => {
    const previousPath = getPreviousStep(step, currentPath, form);
    navigate(previousPath);
  };

  return { handleBack, handleClose };
};

export default useFormNavigation;
