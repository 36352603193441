import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  StyledContainer,
  StyledInput,
  StyledNameContainer,
} from './Contact.styles';
import { CheckBoxInputHook, FormActions, StringInputHook } from '@/components';
import { UserFormContext } from '@/context';
import {
  ContactSchema,
  formatPhoneNumber,
  CONTACT_OPTIONS,
  TContact,
} from '@/utils';

const Contact = () => {
  const { form, saveFormData } = useContext(UserFormContext);
  const navigate = useNavigate();

  const formMethods = useForm<TContact>({
    resolver: zodResolver(ContactSchema),
    defaultValues: form?.personalInfo,
  });

  const { handleSubmit, reset } = formMethods;

  const handleFormSubmit = (data: TContact) => {
    saveFormData({ personalInfo: data });
    navigate('/services/schedule');
    reset();
  };

  return (
    <FormProvider {...formMethods}>
      <StyledContainer>
        <StyledInput>
          <StyledNameContainer>
            <StringInputHook
              name="firstName"
              label="First Name"
              placeholder="First Name"
              width={{ xs: '100%', sm: '100%', md: '220px' }}
            />
            <StringInputHook
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              width={{ xs: '100%', sm: '100%', md: '220px' }}
            />
          </StyledNameContainer>
          <StringInputHook
            name="email"
            label="What’s a good email for you?"
            placeholder="Email"
            fullWidth
            helperText="We will never spam you!"
          />
          <StringInputHook
            placeholder="(303) 210-0000"
            format={formatPhoneNumber}
            label="What’s a good phone number?"
            name="phone"
            fullWidth
          />
        </StyledInput>

        <CheckBoxInputHook
          name="contactMethod"
          options={CONTACT_OPTIONS}
          width="100px"
          height={'55px'}
          gap="16px"
          borderRadius={'4px'}
          justifyContent="center"
          label="How should we contact you?"
        />
      </StyledContainer>
      <FormActions onSubmit={handleSubmit(handleFormSubmit)} />
    </FormProvider>
  );
};

export default Contact;
