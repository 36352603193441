import { format } from 'date-fns';

export const formatPhoneNumber = (value: string) => {
  const cleaned = value.replace(/\D/g, '');
  let formatted = '';
  if (!cleaned.length) return '';
  formatted = `(${cleaned.substring(0, 3)}`;
  if (cleaned.length > 3) {
    formatted += `) ${cleaned.substring(3, 6)}`;
    if (cleaned.length > 6) {
      formatted += `-${cleaned.substring(6, 10)}`;
    }
  }

  return formatted;
};

export const dateFormat = (date: string) => {
  const value = format(new Date(date), 'MMMM dd, yyyy');
  return value;
};
