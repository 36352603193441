import { FC, useState, useEffect } from 'react';
import { UserFormContext } from './ServiceContext';
import { IUserForm } from '@/utils';
import { getUserFormData, saveUserFormData } from '@/services';

interface UserFormContextProps {
  children: React.ReactNode;
}

const initialValues: IUserForm = {
  serviceNeed: '',
  serviceLooking: '',
  address: '',
  serviceId: '',
  emergency: '',
  personalInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    contactMethod: '',
  },
  issuesExplain: '',
  date: '',
  time: '',
  serviceCost: 0,
  serviceName: '',
};

const UserFormProvider: FC<UserFormContextProps> = ({ children }) => {
  const [form, setForm] = useState<Partial<IUserForm> | null>(getUserFormData);
  const [tenantId, setTenantId] = useState<string>(() => {
    const savedTenantId = localStorage.getItem('tenantId');
    return savedTenantId ? JSON.parse(savedTenantId) : '';
  });

  const [step, setStep] = useState<number>(() => {
    const savedStep = localStorage.getItem('userFormStep');
    return savedStep ? JSON.parse(savedStep) : 1;
  });

  const [heading, setHeading] = useState<string>(() => {
    const savedHeading = localStorage.getItem('userFormHeading');
    return savedHeading || 'Which service do you need?';
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem('userFormData', JSON.stringify(form));
  }, [form]);

  useEffect(() => {
    localStorage.setItem('tenantId', JSON.stringify(tenantId));
  }, [tenantId]);

  useEffect(() => {
    localStorage.setItem('userFormStep', JSON.stringify(step));
    localStorage.setItem('userFormPath', window.location.pathname);
  }, [step, window.location.pathname]);

  useEffect(() => {
    localStorage.setItem('userFormHeading', heading);
  }, [heading]);

  const resetForm = () => {
    setForm(initialValues);
    setStep(1);
    setHeading('Which service do you need?');
    localStorage.removeItem('userFormData');
    localStorage.removeItem('userFormStep');
    localStorage.removeItem('userFormHeading');
    localStorage.removeItem('userFormPath');
    localStorage.removeItem('tenantId');
  };

  const saveFormData = (data: Partial<IUserForm>) => {
    const userFormData = { ...form, ...data };
    setForm(userFormData);
    saveUserFormData(userFormData);
  };

  return (
    <UserFormContext.Provider
      value={{
        form,
        step,
        heading,
        isLoading,
        setForm,
        setStep,
        setHeading,
        setIsLoading,
        resetForm,
        saveFormData,
        tenantId,
        setTenantId,
      }}
    >
      {children}
    </UserFormContext.Provider>
  );
};

export { UserFormProvider };
