import { IUserForm } from './schemas';

export const humanizeText = (text: string | undefined) => {
  if (!text) return text;
  return text.replace(/_/g, ' ');
};

export const getPreviousStep = (
  currentStep: number,
  currentPath: string,
  form: Partial<IUserForm> | null,
) => {
  if (!form) return '/services';
  switch (currentStep) {
    case 2:
      return '/services';
    case 3:
      if (currentPath === '/services/wrong-address') return '/services/address';
      return form.serviceNeed === 'Other' ? '/services' : '/services/detail';
    case 4:
      return '/services/address';
    case 5:
      if (form.serviceNeed === 'Other' || form.serviceLooking === 'Other')
        return currentPath === '/services/emergency'
          ? '/services/issue-detail'
          : '/services/address';
      if (form.serviceId === 'Other' && currentPath === '/services/emergency')
        return '/services/issue-detail';
      return '/services/help';
    case 6:
      return '/services/emergency';
    case 7:
      return '/services/contact';
    case 8:
      return '/services/schedule';
    default:
      return '/services/address';
  }
};
