import { ProgressStyle } from './ProgressBar.styles';

interface IStep {
  step: number;
  totalSteps: number;
}

const ProgressBar: React.FC<IStep> = ({ step, totalSteps }) => {
  const value = (step / totalSteps) * 100;
  return <ProgressStyle variant="determinate" value={value} />;
};

export default ProgressBar;
