import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { request } from '@/utils';

const getCategoriesByTenantIdApi = async (tenantId: string): Promise<any> => {
  const response = await request({
    url: `/categories/public?tenantId=${tenantId}`,
    method: 'get',
  });
  return (response as AxiosResponse<any>).data;
};

export const useFetchCategoriesByTenantId = (
  tenantId: string,
  navigate: any,
) => {
  return useQuery<any, AxiosError>(
    ['catgories', tenantId],
    () => getCategoriesByTenantIdApi(tenantId),
    {
      enabled: !!tenantId,
      onSuccess: data => {
        if (Array.isArray(data) && data.length === 0) {
          navigate('/');
        }
      },
      onError: (error: any) => {
        console.error('Error fetching categories:', error.message);
        if (error.response?.status === 404 || error.response?.status === 400) {
          navigate('/');
        }
      },
    },
  );
};

const getServiceByTenantIdApi = async (
  tenantId: string,
  subCategoryId?: string,
): Promise<any> => {
  const url =
    `/services/public?tenantId=${tenantId}` +
    (subCategoryId && subCategoryId !== 'Other'
      ? `&subCategoryId=${subCategoryId}`
      : '');

  const response = await request({
    url,
    method: 'get',
  });

  return (response as AxiosResponse<any>).data;
};

export const useFetchServiceByTenantId = (
  tenantId: string,
  subCategoryId: string,
) => {
  return useQuery<any, AxiosError>(
    ['services', tenantId, subCategoryId],
    () => getServiceByTenantIdApi(tenantId, subCategoryId),
    {
      enabled: !!tenantId,
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

const getSlotsAvailablityApi = async ({
  tenantId,
  serviceId,
  date,
}: {
  tenantId: string;
  serviceId?: string;
  date: string;
}) => {
  const url = `availability/dates?tenantId=${tenantId}&date=${date}${serviceId && serviceId !== 'Other' ? `&serviceId=${serviceId}` : ''}`;
  return request({
    url,
    method: 'get',
  });
};

export const useFetchSlotsAvailability = ({
  tenantId,
  serviceId,
  date,
}: {
  tenantId: string;
  serviceId?: string;
  date: string;
}) => {
  return useQuery<any, AxiosError>(
    ['getSlots', tenantId, serviceId, date],
    () => getSlotsAvailablityApi({ tenantId, serviceId, date }),
    {
      enabled: !!tenantId,
      onSuccess: data => {},
      onError: (error: AxiosError) => {},
    },
  );
};
