import React, { useCallback } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import StringInput, { StringInputProps } from './StringInput';

export interface StringInputHookProps<T extends FieldValues>
  extends Omit<StringInputProps, 'name'> {
  name: FieldPath<T>;
  format?: (value: string) => string;
}

function StringInputHook<T extends FieldValues = FieldValues>({
  name,
  format,
  ...props
}: StringInputHookProps<T>) {
  const { field, fieldState } = useController({ name });
  const { onChange, value } = field;

  const errorMessage = fieldState.error?.message;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let val = e.target.value;
      if (format) val = format(val);
      onChange(val);
    },
    [field, format],
  );

  return (
    <StringInput
      id={name}
      value={value || ''}
      onChange={handleChange}
      error={errorMessage ?? undefined}
      {...props}
    />
  );
}

export default StringInputHook;
