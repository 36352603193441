import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';
import { customTheme } from './theme';
import { BrowserRouter } from 'react-router-dom';
import { UserFormProvider } from './context';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();
root.render(
  <ThemeProvider theme={customTheme}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <CssBaseline />
        <UserFormProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UserFormProvider>
      </React.StrictMode>
      {process.env.REACT_APP_NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )}
    </QueryClientProvider>
  </ThemeProvider>,
);
