const colors = {
  // main
  primary: '#262626',
  secondary: '#279660',
  secondaryLight: `rgba(39, 150, 96, 0.5)`,
  tertiaryMain: '#C9C9C9',
  tertiaryLight: '#F1F1F1',

  // status
  info: '#2F80ED',
  success: '#27AE60',
  warning: '#E2B93B',
  error: '#EB5757',

  // black
  black1: '#1E1E1E',
  black2: '#1D1D1D',
  black3: '#282828',
  white: '#FFFFFF',

  // grey
  grey50: '#E0E0E0',
  grey100: '#BDBDBD',
  grey200: '#828282',
  grey300: '#4F4F4F',
  grey400: '#333333',
};
export default colors;
