import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Typography } from '@mui/material';

import { StyledContainer } from './Issue.styles';
import { FormActions, StringInputHook } from '@/components';
import { UserFormContext } from '@/context';
import { ServiceIssueSchema, TServiceIssue, humanizeText } from '@/utils';

const ServiceIssue = () => {
  const { form, saveFormData, setHeading } = useContext(UserFormContext);
  const navigate = useNavigate();
  useEffect(() => {
    setHeading('You selected other, can you tell us the issue?');
  }, []);
  const formMethods = useForm<TServiceIssue>({
    resolver: zodResolver(ServiceIssueSchema),
    defaultValues: {
      description: form?.issuesExplain || '',
    },
  });

  const handleFormSubmit = async (data: TServiceIssue) => {
    saveFormData({ issuesExplain: data.description });
    navigate('/services/emergency');
  };

  const issue =
    form?.serviceNeed === 'Other'
      ? ' Briefly explain the issue you’re facing'
      : ` Briefly explain the issue you’re facing with the ${humanizeText(
          form?.serviceName as string,
        )}?`;

  return (
    <FormProvider {...formMethods}>
      <StyledContainer>
        <Typography variant="body1" textAlign="center" paddingX="8px">
          {issue}
        </Typography>
        <StringInputHook
          name="description"
          placeholder="Start typing here."
          multiline={true}
          rows={4}
          width={{
            xs: '100%',
            md: '380px',
            lg: '557px',
          }}
        />
      </StyledContainer>
      <FormActions onSubmit={formMethods.handleSubmit(handleFormSubmit)} />
    </FormProvider>
  );
};

export default ServiceIssue;
