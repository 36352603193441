import {
  createTheme,
  responsiveFontSizes,
  PaletteColorOptions,
} from '@mui/material/styles';
import colors from './colors';
import typographyVariants from './typography';
declare module '@mui/material/styles' {
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
    black: PaletteColorOptions;
  }
}
let theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondaryLight,
    },
    tertiary: {
      main: colors.tertiaryMain,
      light: colors.tertiaryLight,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.error,
    },
    black: {
      main: colors.black1,
      light: colors.black2,
      dark: colors.black3,
    },
    grey: {
      50: colors.grey50,
      100: colors.grey100,
      200: colors.grey200,
      300: colors.grey300,
      400: colors.grey400,
    },
    text: {
      primary: colors.primary,
      secondary: colors.grey200,
    },
  },
  typography: {
    fontFamily: 'Inter, "Roboto", sans-serif',
    ...typographyVariants,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: '275px',
          height: '55px',
          borderRadius: '8px',
          margin: '8px',
          textTransform: 'none',
          fontWeight: 700,
          fontSize: '16px',
          paddingTop: '16px',
          paddingBottom: '16px',
          lineHeight: '22.4px',
        },
        containedPrimary: {
          backgroundColor: colors.secondary,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.secondary,
          },
          '&:active': {
            backgroundColor: colors.secondary,
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: colors.secondaryLight,
            color: colors.white,
          },
        },
        outlinedPrimary: {
          border: `2px solid ${colors.primary}`,
          color: colors.primary,
          backgroundColor: colors.white,
          '&:hover': {
            backgroundColor: colors.grey50,
            border: `2px solid ${colors.primary}`,
          },
          '&:active': {
            backgroundColor: colors.grey100,
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: colors.grey200,
            color: colors.grey400,
          },
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);
export { theme as customTheme };
