import { useRoutes } from 'react-router-dom';
import { Typography, Box, Grid } from '@mui/material';
import { PlenumproIcon } from '@/assets/icons';
import { styles } from './pages/TemporaryPage/Temporary.styles';
import getRoutes from './routes';
import NotFound from './pages/NotFound';
import { useEffect } from 'react';

const App = () => {
  const routes = useRoutes(getRoutes());

  const googlePlacesApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
  const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

  useEffect(() => {
    if (!isDevelopment && googlePlacesApiKey) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googlePlacesApiKey}&libraries=places`;
      script.async = true;
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [googlePlacesApiKey]);

  return (
    <>
      {!routes && (
        <Grid
          container
          sx={{
            ...styles.container,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={styles.iconContainer}>
            <PlenumproIcon />
          </Box>
          <Grid item sx={styles.contentArea}>
            <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
              No valid company found! Please contact your company to get a valid
              url.
            </Typography>
            <NotFound />
          </Grid>
        </Grid>
      )}
      {routes}
    </>
  );
};

export default App;
