import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  StyledInfoBox,
  StyledInfoText,
  StyledBox,
  StyledConfirmationMessage,
  StyledContainer,
  StyledText,
} from './Confirmation.styles';
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  ServiceIcon,
  DollarIcon,
  InfoIcon,
} from '@/assets/icons';
import { FormActions, ConfirmationModal } from '@/components';
import { UserFormContext } from '@/context';
import { useFormNavigation } from '@/hooks';
import { IUserForm, humanizeText } from '@/utils';
import { useAddScheduling, useFetchServiceByTenantId } from '@/services';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const ConfirmSchedule = () => {
  const { form, setIsLoading, setForm, tenantId } = useContext(UserFormContext);
  const { mutate: addScheduling } = useAddScheduling();
  const { handleClose } = useFormNavigation();

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async () => {
    setOpenDialog(false);
    const payload = {
      tenantId,
      personalInfo: {
        firstName: form?.personalInfo?.firstName ?? '',
        lastName: form?.personalInfo?.lastName ?? '',
        email: form?.personalInfo?.email ?? '',
        phone: form?.personalInfo?.phone ?? '',
        contactMethod: form?.personalInfo?.contactMethod ?? 'Any',
      },
      address: form?.address ?? '',
      categoryId: form?.serviceNeed,
      subCategoryId: form?.serviceLooking,
      serviceId: form?.serviceId ?? '',
      serviceCost: form?.serviceCost ?? 0,
      emergency: form?.emergency ?? 'No',
      issuesExplain: form?.issuesExplain ?? '',
      date: form?.date ?? '',
      time: form?.time ?? '',
    };

    await addScheduling(payload);
    handleClose();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      setOpenDialog(true);
    } catch (error) {
      console.error('Scheduling submission failed', error);
      setIsLoading(false);
    }
  };

  const { data: services } = useFetchServiceByTenantId(
    tenantId ?? '',
    form?.serviceLooking ?? '',
  );

  const service = services?.find((c: any) => c.id === form?.serviceId);

  useEffect(() => {
    if (service) {
      setForm({
        ...form,
        serviceCost: service.price ?? 0,
      } as IUserForm);
    }
  }, [service, form, setForm]);

  const confirmationData = [
    {
      label: 'Date:',
      value: form?.date ? formatDate(form.date) : 'N/A',
      icon: <CalendarIcon />,
    },
    { label: 'Time:', value: form?.time || 'N/A', icon: <ClockIcon /> },
    {
      label: 'Location:',
      value: form?.address || 'N/A',
      icon: <LocationIcon color="#828282" />,
    },
    {
      label: 'Service:',
      value: humanizeText(service?.name ?? '') || 'N/A',
      icon: <ServiceIcon />,
    },
    {
      label: 'Cost:',
      value: service?.price ? `$${service.price}` : 'N/A',
      icon: <DollarIcon />,
      additionalInfo: (
        <StyledInfoBox>
          <StyledInfoText>
            <InfoIcon />
            Waived with any repair or service by us
          </StyledInfoText>
        </StyledInfoBox>
      ),
    },
  ];

  return (
    <Box maxWidth={{ xs: '333px', md: '540px' }}>
      <StyledContainer>
        <Table>
          <TableBody>
            {confirmationData.map((item, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ paddingX: '0px', verticalAlign: 'top' }}>
                  <StyledBox>
                    {item.icon}
                    <StyledText>{item.label}</StyledText>
                  </StyledBox>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{item.value}</Typography>
                  {item.additionalInfo && item.additionalInfo}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledContainer>
      <StyledConfirmationMessage variant="body1">
        You&apos;ll receive confirmation from us after submitting. This can take
        up to 15 minutes.
      </StyledConfirmationMessage>

      <ConfirmationModal
        open={openDialog}
        onClose={handleCloseDialog}
        title="Success!"
        message="Your form has been successfully submitted! Thank you for scheduling your service with us."
        onConfirm={handleConfirmDialog}
      />
      <FormActions onSubmit={handleSubmit} />
    </Box>
  );
};

export default ConfirmSchedule;
