const ClockIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C6.3 1.5 1.5 6.3 1.5 12C1.5 17.7 6.3 22.5 12 22.5C17.7 22.5 22.5 17.7 22.5 12C22.5 6.3 17.7 1.5 12 1.5ZM15.4403 16.5L11.25 12.3075V5.25H12.75V11.6865L16.5 15.4395L15.4403 16.5Z"
        fill="#828282"
      />
    </svg>
  );
};

export default ClockIcon;
