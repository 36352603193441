import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { styles } from './Layout.styles';
import { PlenumproIcon } from '@/assets/icons';
import { CustomProgressBar } from '@/components';
import { useFormNavigation } from '@/hooks';
import { UserFormContext } from '@/context';
import { isIssueDetailPage } from '@/services';
import { MAP_ROUTE_WITH_STEPER } from '@/utils';

const FormLayout = () => {
  const navigate = useNavigate();
  const { handleClose } = useFormNavigation();
  const { step, heading, form } = useContext(UserFormContext);
  useEffect(() => {
    if (step) {
      const targetRoute = isIssueDetailPage
        ? '/services/issue-detail'
        : MAP_ROUTE_WITH_STEPER[step];
      navigate(targetRoute);
    }
  }, []);

  return (
    <Grid sx={styles.container}>
      <Grid sx={styles.iconContainer} onClick={() => navigate('/services')}>
        <PlenumproIcon />
      </Grid>
      <Grid sx={styles.contentArea}>
        <Grid sx={styles.headingContainer}>
          {step !== 1 && (
            <Box
              sx={{
                width: { xs: '100%', lg: 'auto' },
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton sx={styles.closeIconContainer} onClick={handleClose}>
                <CloseIcon sx={{ width: 24, height: 24 }} />
              </IconButton>
            </Box>
          )}
          <Typography
            variant="h5"
            color="primary"
            align="center"
            sx={styles.heading}
          >
            {heading}
          </Typography>
        </Grid>
        <Box
          width="100%"
          sx={{
            px: { xs: '10px', sm: '30px', md: '50px', lg: '90px' },
          }}
        >
          <CustomProgressBar
            step={step}
            totalSteps={Object.keys(MAP_ROUTE_WITH_STEPER).length}
          />
        </Box>
        <Grid sx={styles.contentWrapper}>
          <Outlet />
        </Grid>
        <Box id="footer-actions-portal" />
      </Grid>
    </Grid>
  );
};

export default FormLayout;
