import { IUserForm } from '@/utils';

const USER_FORM_DATA_KEY = 'userFormData';

export const saveUserFormData = async (data: Partial<IUserForm>) => {
  localStorage.setItem(USER_FORM_DATA_KEY, JSON.stringify(data));
};

export const getUserFormData = (): Partial<IUserForm> | null => {
  const savedFormData = localStorage.getItem(USER_FORM_DATA_KEY);
  return savedFormData ? JSON.parse(savedFormData) : null;
};

export const isIssueDetailPage =
  localStorage.getItem('userFormPath') === '/services/issue-detail-detail';
