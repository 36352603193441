import { Box, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: '670px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '600px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '490px',
  },
}));
export const StyledGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '24px',
}));
export const StyledDisabledButton = styled(IconButton)({
  '&.Mui-disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    opacity: 0.3,
  },
});
export const StyledOptions = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
});

export const StyledTimeOptions = styled(Box)(({ theme }) => ({
  paddingLeft: theme.breakpoints.down('md')
    ? '12px'
    : theme.breakpoints.between('md', 'lg')
      ? '0px'
      : theme.breakpoints.up('lg')
        ? '5px'
        : '0px',
}));
