import React from 'react';

const SuccessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 128 128"
    >
      <circle
        cx="64.5"
        cy="64.5"
        r="47.5"
        stroke="#279660"
        strokeWidth="4"
      ></circle>
      <path
        stroke="#279660"
        strokeWidth="4"
        d="M91.0244 50.2817L57.2818 84.0243L38.0001 64.7426"
      ></path>
    </svg>
  );
};

export default SuccessIcon;
