import { createContext } from 'react';
import { IUserForm } from '@/utils';

interface IUserFormContext {
  form: Partial<IUserForm> | null;
  step: number;
  heading: string | null;
  isLoading: boolean;
  setForm: (form: Partial<IUserForm> | null) => void;
  setStep: (step: number) => void;
  setHeading: (heading: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  resetForm: () => void;
  saveFormData: (data: Partial<IUserForm>) => void;
  tenantId: string;
  setTenantId: (tenantId: string) => void;
}

const UserFormContext = createContext<IUserFormContext>({
  form: null,
  step: 1,
  heading: 'Which service do you need?',
  isLoading: false,
  setForm: () => {},
  setStep: () => {},
  setHeading: () => {},
  setIsLoading: () => {},
  resetForm: () => {},
  saveFormData: () => {},
  tenantId: '',
  setTenantId: () => {},
});

export { UserFormContext };
