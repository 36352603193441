import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

export const ProgressStyle = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: 6,
  borderRadius: '15px',
  backgroundColor: theme.palette.grey[50],
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '15px',
  },
}));
