const RightArrowIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="#E0E0E0" />
      <path
        d="M22.2754 18L14.7754 10.5L13.7254 11.55L20.1754 18L13.7254 24.45L14.7754 25.5L22.2754 18Z"
        fill="black"
      />
    </svg>
  );
};

export default RightArrowIcon;
