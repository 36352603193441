export const MAP_ROUTE_WITH_STEPER: Record<number, string> = {
  1: '/services',
  2: '/services/detail',
  3: '/services/address',
  4: '/services/help',
  5: '/services/emergency',
  6: '/services/contact',
  7: '/services/schedule',
  8: '/services/confirmation',
};

interface RouteConfig {
  step: number;
  path: string;
  getHeading: (firstName?: string) => string;
}

export const ROUTE_CONFIG: RouteConfig[] = [
  {
    step: 1,
    path: '/services',
    getHeading: () => 'Which service do you need?',
  },
  {
    step: 2,
    path: '/services/detail',
    getHeading: () =>
      'Got it, which one of these services are you looking for?',
  },
  {
    step: 3,
    path: '/services/address',
    getHeading: () => "Perfect, what's your address?",
  },
  {
    step: 4,
    path: '/services/help',
    getHeading: () =>
      'Great, we serve your area. Now, tell us how we can help.',
  },
  {
    step: 5,
    path: '/services/emergency',
    getHeading: () => 'Got it. Is this an emergency?',
  },
  {
    step: 6,
    path: '/services/contact',
    getHeading: () => 'Got it. Let’s grab your contact information',
  },
  {
    step: 7,
    path: '/services/schedule',
    getHeading: firstName =>
      firstName
        ? `Thank you, ${firstName}. What days or timing work best for you?`
        : 'What days or timing work best for you?',
  },
  {
    step: 8,
    path: '/services/confirmation',
    getHeading: () =>
      'Thank you! Please review the details and confirm schedule.',
  },
  {
    step: 5,
    path: '/services/issue',
    getHeading: () => 'You selected other, can you tell us the issue?',
  },
  {
    step: 3,
    path: '/services/wrong-address',
    getHeading: () => 'Outside service area',
  },
];
