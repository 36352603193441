import { z } from 'zod';

export const ServiceTypeSchema = z.object({
  serviceNeed: z
    .string({ message: 'Please choose an option before proceeding' })
    .min(1, 'Please choose an option before proceeding'),
});
export type TServiceType = z.infer<typeof ServiceTypeSchema>;

export const ServiceDetailSchema = z.object({
  serviceLooking: z
    .string({ message: 'Please choose an option before proceeding' })
    .min(1, 'Please choose an option before proceeding'),
});
export type TServiceDetail = z.infer<typeof ServiceDetailSchema>;

export const ServiceHelpSchema = z.object({
  serviceId: z
    .string({ message: 'Please choose an option before proceeding' })
    .min(1, 'Please choose an option before proceeding'),
});
export type TServiceHelp = z.infer<typeof ServiceHelpSchema>;

export const ServiceEmergencySchema = z.object({
  serviceEmergency: z
    .string({ message: 'Please choose an option before proceeding' })
    .min(1, 'Please choose an option before proceeding'),
});
export type TServiceEmergency = z.infer<typeof ServiceEmergencySchema>;

export const ServiceScheduleSchema = z.object({
  dateoptions: z
    .string({ message: 'Please select your date slot' })
    .min(1, 'Please select your date slot'),
  timeoptions: z
    .string({ message: 'Please select your time slot' })
    .min(1, 'Please select your time slot'),
});
export type TServiceSchedule = z.infer<typeof ServiceScheduleSchema>;

export const ServiceIssueSchema = z.object({
  description: z
    .string({
      message: 'Please describe the issue you are facing',
    })
    .min(1, {
      message: 'Please describe the issue you are facing',
    }),
});

export type TServiceIssue = z.infer<typeof ServiceIssueSchema>;

export const ContactSchema = z.object({
  firstName: z
    .string({ required_error: 'First name is required' })
    .min(1, { message: 'First name is required' }),
  lastName: z
    .string({ required_error: 'Last name is required' })
    .min(1, { message: 'Last name is required' }),
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Please provide valid email' }),
  phone: z
    .string()
    .min(1, { message: 'Phone number is required' })
    .regex(/^\(\d{3}\) \d{3}-\d{4}$/, {
      message: 'Please provide valid phone number',
    }),
  contactMethod: z
    .string({ message: 'Please choose a contact option' })
    .nonempty({ message: 'Please choose a contact option' }),
});

export type TContact = z.infer<typeof ContactSchema>;
