import { InputHTMLAttributes, forwardRef } from 'react';
import {
  Typography,
  FormControl,
  FormControlProps,
  FormLabelProps,
  TextFieldProps,
  FormLabel,
} from '@mui/material';
import StyledStringInput from './StringInput.styles';

export interface StringInputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'size' | 'color' | 'width'
  > {
  // name: string;
  label?: string;
  error?: string | undefined;
  color?: TextFieldProps['color'];
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  multiline?: boolean;
  rows?: number;
  width?: string | Record<string, string>;
  fullWidth?: boolean;
  helperText?: string;
}

const StringInput = forwardRef<HTMLInputElement, StringInputProps>(
  (
    {
      name,
      label,
      type = 'text',
      error,
      required,
      placeholder,
      color = 'primary',
      formControlProps,
      formLabelProps,
      multiline = false,
      rows = 4,
      width,
      fullWidth,
      helperText,
      ...props
    }: StringInputProps,
    ref,
  ) => {
    return (
      <FormControl {...formControlProps} required={required}>
        {label && (
          <FormLabel
            {...formLabelProps}
            sx={{
              color: 'primary.main',
              fontWeight: 700,
              fontSize: '16px',
              marginBottom: '8px',
            }}
          >
            {label}
          </FormLabel>
        )}
        <StyledStringInput
          type={type}
          variant="outlined"
          inputRef={ref}
          placeholder={placeholder}
          error={Boolean(error)}
          color={color}
          multiline={multiline}
          rows={multiline ? rows : undefined}
          width={width}
          fullWidth={fullWidth}
          {...props}
        />
        {error && (
          <Typography color="error" fontSize={14} paddingTop={1}>
            {error}
          </Typography>
        )}
        {helperText && (
          <Typography color="grey.100" variant="body2" paddingTop={'8px'}>
            {helperText}
          </Typography>
        )}
      </FormControl>
    );
  },
);

export default StringInput;
