import { FC } from 'react';
import { ButtonProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
}

const CustomButton: FC<CustomButtonProps> = ({
  disabled = false,
  loading = false,
  children,
  variant = 'contained',
  sx = {},
  ...buttonProps
}) => (
  <LoadingButton
    variant={variant}
    disabled={disabled || loading}
    loading={loading}
    sx={{
      width: {
        xs: '100%',
        md: '180px',
        lg: '275px',
      },
      ...sx,
    }}
    {...buttonProps}
  >
    {!loading && children}
  </LoadingButton>
);

export default CustomButton;
