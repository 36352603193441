import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialog } from './ConfirmationModal.styles';
import { CustomButton } from '@/components';
import { SuccessIcon } from '@/assets/icons';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
}) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <Box display="flex" justifyContent="flex-end" position="relative">
          <IconButton
            onClick={onClose}
            sx={{
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <SuccessIcon />
        </Box>
        <DialogTitle>{title}</DialogTitle>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="contained" onClick={onConfirm}>
          Confirm
        </CustomButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default ConfirmationModal;
