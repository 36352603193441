import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import Typography from '@mui/material/Typography';

import { StyledContainer } from './Help.styles';
import { FormActions, CheckBoxInputHook } from '@/components';
import { UserFormContext } from '@/context';
import { humanizeText, ServiceHelpSchema, TServiceHelp } from '@/utils';
import { useFetchServiceByTenantId } from '@/services';
import { FurnaceIcon, OtherIcon } from '@/assets/icons';

const ServiceHelp = () => {
  const { form, saveFormData, tenantId } = useContext(UserFormContext);

  const { data: services } = useFetchServiceByTenantId(
    tenantId ?? '',
    form?.serviceLooking ?? '',
  );

  const navigate = useNavigate();
  const formMethods = useForm<TServiceHelp>({
    resolver: zodResolver(ServiceHelpSchema),
    defaultValues: {
      serviceId: form?.serviceId,
    },
  });

  const filteredServices = [
    ...(Array.isArray(services) ? services : []).map((service: any) => {
      return {
        label: service.name,
        value: service.id,
        image: <FurnaceIcon />,
      };
    }),
    {
      label: 'Other',
      value: 'Other',
      image: <OtherIcon />,
    },
  ];

  const handleFormSubmit = async (data: TServiceHelp) => {
    saveFormData(data);
    if (data.serviceId === 'Other') {
      navigate('/services/issue-detail');
    } else {
      navigate('/services/emergency');
    }
  };

  return (
    <FormProvider {...formMethods}>
      <StyledContainer>
        <Typography variant="body1" textAlign="center" paddingX="8px">
          What is the problem you’re experiencing with the{' '}
          {humanizeText(`${form?.serviceName}`)}?
        </Typography>
        <CheckBoxInputHook
          name="serviceId"
          options={filteredServices}
          width={{ xs: '150px', md: '191px' }}
          gap={{ xs: '32px', lg: '12px', xl: '21px' }}
          justifyContent={{ xs: 'center', lg: 'center' }}
          height={{ xs: '150px', md: '182px' }}
        />
      </StyledContainer>
      <FormActions onSubmit={formMethods.handleSubmit(handleFormSubmit)} />
    </FormProvider>
  );
};

export default ServiceHelp;
