const LocationIcon = ({ color = '#262626' }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M4 10.5C4.00006 4.48668 8 2.5 12 2.5C16 2.5 20 4.9 20 10.5C20 16.1 14.6667 20.8333 12 22.5C9.16667 21 3.99994 16.5 4 10.5Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="12"
        cy="10.5"
        r="3"
        fill={color}
        stroke="#FEFEFE"
        strokeWidth="2"
      />
    </svg>
  );
};

export default LocationIcon;
