export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    bgcolor: '#F5F5F5',
    paddingX: { xs: '10px', md: '100px', lg: '100px' },
    paddingBottom: { xs: '16px', sm: '38px' },
  },
  iconContainer: {
    marginY: { xs: '24px', sm: '38px' },
  },
  contentArea: {
    width: '100%',
    minHeight: '70vh',
    bgcolor: 'white',
    borderRadius: '12px',
    paddingTop: { xs: '14px', md: '30px', lg: '67px' },
    boxShadow: '0px 4px 17.4px 0px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '42px',
  },
  typingText: {
    color: 'text.primary',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex', 
    alignItems: 'center', 
  },
  dot: {
    fontSize: '2rem', 
    fontWeight: 'bold',
    color: 'black.main',
    transition: 'opacity 0.5s ease', 
    marginLeft: '5px', 
  },
};
