import { TypographyVariantsOptions } from '@mui/material/styles';
const typographyVariants: TypographyVariantsOptions = {
  h1: {
    fontSize: '56px',
    lineHeight: 1.1,
    fontWeight: 700,
    '@media (max-width:1280px)': {
      fontSize: '48px',
    },
    '@media (max-width:960px)': {
      fontSize: '40px',
    },
    '@media (max-width:640px)': {
      fontSize: '32px',
    },
  },
  h2: {
    fontSize: '48px',
    lineHeight: 1.1,
    fontWeight: 700,
    '@media (max-width:1280px)': {
      fontSize: '40px',
    },
    '@media (max-width:960px)': {
      fontSize: '32px',
    },
    '@media (max-width:640px)': {
      fontSize: '24px',
    },
  },
  h3: {
    fontSize: '40px',
    lineHeight: 1.1,
    fontWeight: 700,
  },
  h4: {
    fontSize: '32px',
    lineHeight: 1.1,
    fontWeight: 700,
  },
  h5: {
    fontSize: '24px',
    lineHeight: 1.1,
    fontWeight: 700,
  },
  h6: {
    fontSize: '20px',
    lineHeight: 1.1,
    fontWeight: 700,
  },
  body1: {
    fontSize: '16px',
    lineHeight: 1.2,
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '16px',
    lineHeight: 1.2,
    fontWeight: 600,
  },
  caption: {
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: 600,
  },
};
export default typographyVariants;