import { styled, Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    textAlign: 'center',
    fontWeight: theme.typography.h6.fontWeight,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    color: theme.palette.text.primary,
  },
  '& .MuiDialogContent-root': {
    backgroundColor: theme.palette.background.default,
  },
  '& .MuiDialogActions-root': {
    paddingBottom: '20px',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
}));
