import { Box, Typography } from '@mui/material';
import {
  AirConditioningIcon,
  FurnaceIcon,
  HeatPumpsIcon,
  OtherIcon,
} from '@/assets/icons';

export const SERVICES_DATA = [
  {
    label: 'Furnace',
    value: 'Furnace',
    image: <FurnaceIcon />,
  },
  {
    label: 'Air Conditioning',
    value: 'Air_Conditioning',
    image: <AirConditioningIcon />,
  },
  {
    label: 'Heat Pumps',
    value: 'Heat_Pumps',
    image: <HeatPumpsIcon />,
  },
  {
    label: 'Other',
    value: 'Other',
    image: <OtherIcon />,
  },
];

export interface IService {
  label: string;
  value: string;
}

export const SERVICES_DETAILS_DATA: Record<string, IService[]> = {
  Furnace: [
    {
      label: 'Furnace Repair',
      value: 'Furnace Repair',
    },
    {
      label: 'Furnace Replacement',
      value: 'Furnace Replacement',
    },
    {
      label: 'Furnace Maintenance',
      value: 'Furnace Maintenance',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  Air_Conditioning: [
    {
      label: 'Air Conditioning Repair',
      value: 'Air Conditioning Repair',
    },
    {
      label: 'Air Conditioning Replacement',
      value: 'Air Conditioning Replacement',
    },
    {
      label: 'Air Conditioning Maintenance',
      value: 'Air Conditioning Maintenance',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  Heat_Pumps: [
    {
      label: 'Heat Pumps Repair',
      value: 'Heat Pumps Repair',
    },
    {
      label: 'Heat Pumps Replacement',
      value: 'Heat Pumps Replacement',
    },
    {
      label: 'Heat Pumps Maintenance',
      value: 'Heat Pumps Maintenance',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
};

export const SERVICES_HELP_DATA: Record<string, IService[]> = {
  Furnace: [
    {
      label: 'No heating',
      value: 'No heating',
    },
    {
      label: 'Not heating enough',
      value: 'Not heating enough',
    },
    {
      label: 'No air from vents',
      value: 'No air from vents',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  Air_Conditioning: [
    {
      label: 'No cooling',
      value: 'No cooling',
    },
    {
      label: 'Not cooling enough',
      value: 'Not cooling enough',
    },
    {
      label: 'No air from vents',
      value: 'No air from vents',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  Heat_Pumps: [
    {
      label: 'Not heating properly',
      value: 'Not heating properly',
    },
    {
      label: 'Not cooling properly',
      value: 'Not cooling properly',
    },
    {
      label: 'No air from vents',
      value: 'No air from vents',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
};

export const SERVICE_EMERGENCY = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
  {
    label: 'Unsure',
    value: 'Unsure',
  },
];

export const CONTACT_OPTIONS = [
  {
    label: 'Any',
    value: 'Any',
  },
  {
    label: 'Phone',
    value: 'Phone',
  },
  {
    label: 'Email',
    value: 'Email',
  },
  {
    label: 'Text',
    value: 'Text',
  },
];
