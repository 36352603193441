import { useState, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { styles } from './Temporary.styles';
import { PlenumproIcon } from '@/assets/icons';
import { useFetchCategoriesByTenantId } from '@/services';
import { useContext } from 'react';
import { UserFormContext } from '@/context';

const TemporaryPage = () => {
  const navigate = useNavigate();
  const isPreviousData = localStorage.getItem('userFormData');

  const { tenantId } = useParams<{ tenantId: string }>();
  const { setTenantId } = useContext(UserFormContext);
  if (tenantId) {
    setTenantId(tenantId);
  }

  const { data: categories } = useFetchCategoriesByTenantId(
    tenantId ?? '',
    navigate,
  );

  const handleNavigate = () => {
    if (categories) {
      navigate(`/services`);
    } else {
      console.error('Categories are undefined');
    }
  };

  const [text, setText] = useState('Welcome to plenum');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDotVisible, setIsDotVisible] = useState(true);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeoutId = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 50);
      return () => clearTimeout(timeoutId);
    } else {
      const dotTimeout = setTimeout(() => {
        setIsDotVisible(false);
      }, 500); 
      return () => clearTimeout(dotTimeout);
    }
  }, [currentIndex, text.length]);

  return (
    <Grid sx={styles.container}>
      <Box sx={styles.iconContainer}>
        <PlenumproIcon />
      </Box>
      <Grid sx={styles.contentArea}>
        <Box sx={styles.typingText}>
          {text.substring(0, currentIndex)}
          <span style={{
            ...styles.dot,
            opacity: isDotVisible ? 1 : 0,
          }}>
            •
          </span>
        </Box>
        <Button
          variant="contained"
          onClick={handleNavigate}
          aria-label={
            isPreviousData ? 'Go back to previous form data' : 'Start the form'
          }
        >
          {isPreviousData ? 'Complete form' : 'Start'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TemporaryPage;
