const WrongAddressIcon = () => {
  return (
    <svg
      width="173"
      height="172"
      viewBox="0 0 173 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.788 161.465L113.525 123.209C109.799 127.638 105.714 132.105 101.27 136.611C96.8367 141.112 91.9132 145.787 86.4999 150.636C69.6583 135.744 56.9733 121.855 48.4449 108.969C39.9118 96.0834 35.6453 84.3564 35.6453 73.7879C35.6453 69.507 36.1039 65.4268 37.0213 61.5473C37.9386 57.6725 39.2119 53.9984 40.8411 50.5249L14.0234 23.7073L18.9971 18.4541L156.898 156.355L151.788 161.465ZM126.526 105.83L97.2858 76.5973C97.6107 75.9188 97.8257 75.2499 97.9308 74.5906C98.0263 73.9265 98.0741 73.2265 98.0741 72.4908C98.0741 69.2897 96.9465 66.5592 94.6914 64.2993C92.4363 62.0442 89.7058 60.9166 86.4999 60.9166C85.7642 60.9166 85.0666 60.9668 84.4073 61.0671C83.7432 61.1674 83.0719 61.38 82.3934 61.7049L53.8773 33.1816C58.2776 29.5027 63.2441 26.636 68.7768 24.5816C74.3094 22.5272 80.2172 21.4999 86.4999 21.4999C100.656 21.4999 112.673 26.4258 122.548 36.2776C132.419 46.1246 137.355 58.628 137.355 73.7879C137.355 78.6039 136.464 83.6827 134.681 89.0243C132.899 94.3658 130.181 99.9678 126.526 105.83Z"
        fill="#E2A03B"
      />
    </svg>
  );
};

export default WrongAddressIcon;
