import { useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LocationIcon } from '@/assets/icons';
import { FormActions, PlaceAutoComplete } from '@/components';
import { UserFormContext } from '@/context';

const ServiceAddress = () => {
  const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
  const { form, saveFormData } = useContext(UserFormContext);
  const [addressFound, setAddressFound] = useState<boolean>(isDevelopment);
  const navigate = useNavigate();
  const formMethods = useForm({
    defaultValues: {
      address: form?.address,
    },
  });

  const handleChange = (place: any) => {
    if (place) setAddressFound(true);
    else setAddressFound(false);
  };

  const handleFormSubmit = async () => {
    const address = formMethods.getValues('address');
    saveFormData({ address });
    if (!addressFound) navigate('/services/wrong-address');
    else if (form?.serviceNeed === 'Other' || form?.serviceLooking === 'Other')
      navigate('/services/issue-detail');
    else navigate('/services/help');
  };

  return (
    <FormProvider {...formMethods}>
      <PlaceAutoComplete
        name="address"
        icon={<LocationIcon />}
        onChange={handleChange}
      />
      <FormActions onSubmit={formMethods.handleSubmit(handleFormSubmit)} />
    </FormProvider>
  );
};

export default ServiceAddress;
