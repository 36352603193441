import { IUserForm, request } from '@/utils';
import { useMutation, useQueryClient } from 'react-query';

const addScheduling = (data: Partial<IUserForm> | null) =>
  request({ url: '/scheduling/create', method: 'post', data });

export const useAddScheduling = () => {
  const queryClient = useQueryClient();
  return useMutation(addScheduling, {
    onMutate: async newScheduling => {
      return { newScheduling };
    },

    onSuccess: () => {
      queryClient.invalidateQueries('scheduling');
    },
    onError: (error: any) => {
      console.error('Error updating user:', error);
    },
  });
};
