import { useRef, useEffect, FC, ReactNode } from 'react';
import { get, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import {
  ErrorText,
  StyledAutoCompleteContainer,
  StyledInputBase,
} from './PlaceAutoComplete.styles';
import './style.css'

interface PlaceAutoCompleteProps {
  name: string;
  icon?: ReactNode;
  onChange: (place: any) => void;
}

declare global {
  interface Window {
    google: {
      maps: {
        places: {
          Autocomplete: any;
        };
      };
    };
  }
}

const PlaceAutoComplete: FC<PlaceAutoCompleteProps> = ({
  name,
  icon,
  onChange,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const autoCompleteRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const options = {
    componentRestrictions: { country: ['us'] },
    fields: ['address_components', 'geometry', 'name'],
    types: ['address'],
  };

  useEffect(() => {
    if (window.google && window.google.maps && inputRef.current) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options,
      );
      autoCompleteRef.current.addListener('place_changed', async function () {
        const place = await autoCompleteRef.current.getPlace();
        onChange(place);
      });
    }
  }, [onChange]);

  const { ref: registerRef, ...registerProps } = register(name);

  const error = get(errors, name);

  return (
    <StyledAutoCompleteContainer>
      {icon && (
        <Box
          sx={{
            position: 'absolute',
            left: '12px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '2',
          }}
        >
          {icon}
        </Box>
      )}
      <StyledInputBase
        placeholder="Enter zip or street address"
        inputRef={e => {
          inputRef.current = e;
          registerRef(e);
        }}
        {...registerProps}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </StyledAutoCompleteContainer>
  );
};

export default PlaceAutoComplete;
