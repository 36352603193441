import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import { FormActions, CheckBoxInputHook } from '@/components';
import { UserFormContext } from '@/context';
import {
  SERVICE_EMERGENCY,
  ServiceEmergencySchema,
  TServiceEmergency,
} from '@/utils';

const ServiceEmergency = () => {
  const { form, saveFormData } = useContext(UserFormContext);
  const navigate = useNavigate();
  const formMethods = useForm<TServiceEmergency>({
    resolver: zodResolver(ServiceEmergencySchema),
    defaultValues: {
      serviceEmergency: form?.emergency,
    },
  });

  const handleFormSubmit = async (data: TServiceEmergency) => {
    saveFormData({ emergency: data.serviceEmergency });
    navigate('/services/contact');
  };

  return (
    <FormProvider {...formMethods}>
      <CheckBoxInputHook
        name="serviceEmergency"
        options={SERVICE_EMERGENCY}
        width={{ xs: '156px', md: '191px' }}
        gap={'21px'}
        justifyContent={{ xs: 'left', md: 'center' }}
        height={'90px'}
      />
      <FormActions onSubmit={formMethods.handleSubmit(handleFormSubmit)} />
    </FormProvider>
  );
};
export default ServiceEmergency;
