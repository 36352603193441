import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '50vh', 
        paddingX: { xs: '20px', sm: '50px', md: '100px' },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: '50px', sm: '80px', md: '120px' },
          fontWeight: 700,
          color: 'black.main',
          textAlign: 'center',
        }}
      >
        404
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: { xs: 400, sm: 500, md: 600 },
          color: 'error.main',
          mt: 1,
          textAlign: 'center',
        }}
      >
        Company not found!
      </Typography>
    </Box>
  );
};

export default NotFound;
