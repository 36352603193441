import { ReactNode, forwardRef, useState } from 'react';
import { Typography, FormControl, Box } from '@mui/material';
import {
  StyledCheckBoxWrapper,
  StyledOptionBox,
  StyledOptionsContainer,
} from './CheckBoxInput.styles';

interface IOption {
  label: string;
  value: string;
  tooltip?: string;
  image?: JSX.Element;
  day?: string;
  slot?: number;
  disabled?: boolean;
}

export interface CheckBoxInputProps {
  name: string;
  label?: string;
  id?: string;
  error?: ReactNode;
  required?: boolean;
  options: IOption[];
  value?: string | null;
  onChange?: (value: string) => void;
  paddingX?: string | Record<string, string>;
  paddingY?: string | Record<string, string>;
  width?: string | Record<string, string>;
  height?: string | Record<string, string>;
  borderRadius?: string | Record<string, string>;
  gap?: string | Record<string, string>;
  justifyContent?: string | Record<string, string>;
  fontSize?: string;
}

const CheckBoxInput = (
  {
    error,
    options,
    value,
    onChange,
    paddingX,
    paddingY,
    width,
    height,
    gap,
    justifyContent,
    fontSize,
    borderRadius,
  }: CheckBoxInputProps,
  ref: any,
) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (newValue: string) => {
    setSelectedValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <StyledCheckBoxWrapper>
      <FormControl>
        <StyledOptionsContainer gap={gap} justifyContent={justifyContent}>
          {options.map(option => (
            <StyledOptionBox
              key={option.value}
              selected={selectedValue === option.value}
              disabled={option.disabled || false}
              onClick={() => !option.disabled && handleChange(option.value)}
              paddingX={paddingX}
              paddingY={paddingY}
              width={width}
              height={height}
              borderRadius={borderRadius}
            >
              {option.image && option.image}
              {option.day && (
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  color="black.main"
                >
                  {option.day}
                </Typography>
              )}
              <Typography
                fontSize={fontSize}
                textAlign="center"
                fontWeight={selectedValue === option.value ? 700 : 400}
              >
                {option.label}
              </Typography>
              {option.slot && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '12px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    fontSize={'12px'}
                    fontWeight={400}
                    color="grey.200"
                  >
                    {option.slot} {option.slot < 2 ? 'slot' : 'slots'}
                  </Typography>
                </Box>
              )}
            </StyledOptionBox>
          ))}
        </StyledOptionsContainer>
        {error && (
          <Typography color="error" fontSize={14} paddingTop={1}>
            {error}
          </Typography>
        )}
      </FormControl>
    </StyledCheckBoxWrapper>
  );
};

export default forwardRef(CheckBoxInput);
