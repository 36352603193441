const ServiceIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7098 20.29L20.2898 21.71C20.1025 21.8962 19.849 22.0008 19.5848 22.0008C19.3206 22.0008 19.0672 21.8962 18.8798 21.71L6.99982 9.85C6.67463 9.94421 6.33833 9.99465 5.99982 10C5.36312 9.99955 4.73573 9.84711 4.16981 9.55536C3.60388 9.26362 3.11578 8.84099 2.74608 8.32263C2.37638 7.80426 2.13576 7.20513 2.04422 6.57505C1.95269 5.94496 2.01289 5.30213 2.21982 4.7L4.75982 7.24L5.28982 6.71L6.70982 5.29L7.23982 4.76L4.69982 2.22C5.30195 2.01307 5.94478 1.95287 6.57486 2.04441C7.20495 2.13594 7.80408 2.37656 8.32244 2.74626C8.84081 3.11596 9.26343 3.60407 9.55518 4.16999C9.84692 4.73591 9.99936 5.3633 9.99982 6C9.99447 6.33852 9.94402 6.67481 9.84982 7L21.7098 18.88C21.8961 19.0674 22.0006 19.3208 22.0006 19.585C22.0006 19.8492 21.8961 20.1026 21.7098 20.29ZM2.28982 18.88C2.10357 19.0674 1.99902 19.3208 1.99902 19.585C1.99902 19.8492 2.10357 20.1026 2.28982 20.29L3.70982 21.71C3.89718 21.8962 4.15063 22.0008 4.41482 22.0008C4.679 22.0008 4.93245 21.8962 5.11982 21.71L10.5898 16.25L7.75982 13.42M19.9998 2L15.9998 4V6L13.8298 8.17L15.8298 10.17L17.9998 8H19.9998L21.9998 4L19.9998 2Z"
        fill="#828282"
      />
    </svg>
  );
};

export default ServiceIcon;
